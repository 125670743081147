<script setup>
import {isTest} from '@/utils/isTest'

const {isMobile} = useDevice()

const eventDataStore = useEventDataStore()

const props = defineProps({
  labels: {
    type: Object,
    required: true,
    default: () => {},
  },
})

const emit = defineEmits(['change'])

const filterItems = ref()

const selectedLabelFilters = ref(new Set())

const selectLabelFilter = ({id, name}) => {
  if (selectedLabelFilters.value.has(id)) {
    selectedLabelFilters.value.delete(id)
  } else {
    selectedLabelFilters.value.add(id)
  }

  eventDataStore.setFilters(props.labels.name, Array.from(selectedLabelFilters.value))

  emit('change')
}

const scrollDiv = (direction) => {
  const SCROLL_PIXELS = isMobile ? 100 : 200
  const scrollLeft = filterItems.value.scrollLeft

  if (direction === 'prev') {
    filterItems.value.scrollLeft = scrollLeft - SCROLL_PIXELS
  } else {
    filterItems.value.scrollLeft = scrollLeft + SCROLL_PIXELS
  }
}

const showPrevArrow = ref(false)
const showNextArrow = ref(false)

const setArrows = (event) => {
  const scrollWidth = event.target.scrollWidth
  const scrollLeft = event.target.scrollLeft
  const tabsParentWidth = event.target.getBoundingClientRect().width

  showPrevArrow.value = scrollLeft > 10
  showNextArrow.value = scrollLeft < scrollWidth - tabsParentWidth - 10
}

const onResize = () => {
  setArrows({target: filterItems.value})
}

onMounted(() => {
  if (isTest()) {
    return false
  }
  setArrows({target: filterItems.value})
  globalThis.addEventListener('resize', onResize)
})

onBeforeUnmount(() => {
  globalThis.addEventListener('resize', onResize)
})
</script>

<style lang="scss" scoped>
@import 'assets/css/components/index/filter/label.css';
</style>
<template>
  <div class="filter-items-wrapper">
    <span
      class="scroll-prev"
      v-show="showPrevArrow"
      @click="scrollDiv('prev')"
    >
      <img
        alt="back"
        src="/images/common/left-arrow.svg"
        width="10"
      />
    </span>
    <ul
      class="filter-items"
      ref="filterItems"
      @scroll="setArrows"
    >
      <li
        :class="selectedLabelFilters.has(filterItem.id) ? 'filter-item-active' : ''"
        :key="`${index}-${filterItem}`"
        v-for="(filterItem, index) in labels.options"
        @click="selectLabelFilter(filterItem)"
      >
        {{ filterItem.name }}
      </li>
    </ul>
    <span
      class="scroll-next"
      v-show="showNextArrow"
      @click="scrollDiv('next')"
    >
      <img
        alt="back"
        src="/images/common/right-arrow.svg"
        width="10"
      />
    </span>
  </div>
</template>
