<script setup>
const eventDataStore = useEventDataStore()

const props = defineProps({
  range: {
    type: Array,
    required: true,
    default: () => [],
  },
})

const emit = defineEmits(['change'])

const selectedRangeValue = ref()

const onRangeChange = (field, value) => {
  eventDataStore.setFilters(field, value)
  emit('change')
}
</script>

<template>
  <NuRange
    :label="range.name"
    :max="range.maxValue"
    :min="range.minValue"
    type="range"
    v-model="selectedRangeValue"
    @change="(_, value) => onRangeChange(range.name, value)"
  />
</template>
