<script setup>
const eventDataStore = useEventDataStore()

const props = defineProps({
  dropdown: {
    type: Object,
    required: true,
    default: () => {},
  },
})

const emit = defineEmits(['change'])

const selectedDropDownValue = ref({})

const onDropdownChange = (field, value) => {
  eventDataStore.setFilters(field, value ? [value] : value)
  emit('change', field, value)
}
</script>

<template>
  <NuDropDown
    :label="dropdown.label"
    :options="dropdown.options"
    :placeholder="dropdown.label"
    option-key="id"
    option-value="name"
    can-deselect
    v-model="selectedDropDownValue"
    @change="onDropdownChange(dropdown.name, $event)"
  />
</template>
