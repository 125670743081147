<script setup>
const {t, te} = useI18n()
const eventDataStore = useEventDataStore()

const props = defineProps({
  filters: {
    type: Object,
    required: true,
    default: () => {},
  },
})

const emit = defineEmits(['filterChange'])

const filtersList = computed(() => {
  if (!props.filters) {
    return []
  }
  if (!props.filters.active_filters) {
    return []
  }

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1) || ''
  }

  const getLabel = (name) => {
    const translationKey = `comp_nu_filter.${name}`
    if (te(translationKey)) {
      return t(translationKey)
    }
    return capitalize(name) || name
  }

  return props.filters.active_filters.map((item) => {
    return {
      ...item,
      label: getLabel(item.name),
    }
  })
})

const dataFilters = computed(() => {
  if (!filtersList.value) {
    return []
  }

  return filtersList.value.filter((item) => item.display !== 'label')
})

const labels = computed(() => {
  if (!filtersList.value) {
    return []
  }

  return filtersList.value.filter((item) => item.display === 'label')
})

const showFilter = ref(false)

const toggleFilter = () => {
  showFilter.value = !showFilter.value
}

const isDataFiltersSelected = computed(() => {
  if (!filtersList.value) {
    return false
  }

  const {name: filterName} = filtersList.value.find((item) => item.display === 'label')

  return Object.keys(eventDataStore.filters).some((key) => {
    return key !== filterName
  })
})

const showIndicator = computed(() => {
  return isDataFiltersSelected.value && !showFilter.value
})
</script>

<style lang="scss" scoped>
@import 'assets/css/components/global/nu-filter.css';
</style>

<template>
  <div class="filter-wrapper">
    <div class="filter-area">
      <div class="filter-button-wrapper">
        <span
          :class="showFilter ? 'filter-button-active' : ''"
          class="filter-button"
          @click="toggleFilter"
        >
          <img
            :src="showFilter ? '/images/common/filter-icon-white.svg' : '/images/common/filter-icon.svg'"
            alt="filter"
            class="icon-filter"
            width="17"
          />
          <label>{{ $t('comp_nu_filter.filter') }}</label>
          <div
            class="filter-indicator"
            v-show="showIndicator"
          ></div>
        </span>
        <div
          :class="showFilter ? 'filter-active' : 'filter-hide'"
          class="filter-form"
          v-show="showFilter"
        >
          <img
            alt="back"
            class="icon-back-arrow"
            src="/images/common/left-arrow.svg"
            width="10"
            @click="toggleFilter"
          />

          <template
            :key="`data-filter-${index}`"
            v-for="(filter, index) in dataFilters"
          >
            <IndexFilterDropdown
              :dropdown="filter"
              v-if="filter.display === 'dropdown'"
              @change="emit('filterChange')"
            />

            <IndexFilterRange
              :range="filter"
              v-if="filter.display === 'range'"
              @change="emit('filterChange')"
            />
          </template>

          <NuButton
            class="mt-auto apply-filter-btn"
            display="primary"
            @click="toggleFilter"
          >
            {{ $t('comp_nu_filter.apply_filter') }}
          </NuButton>
        </div>

        <IndexFilterLabels
          :labels="labelFilter"
          :key="`label-item-${index}`"
          v-for="(labelFilter, index) in labels"
          @change="emit('filterChange')"
        />
      </div>
    </div>
  </div>
</template>
